import service from '@/app/usecase/routeOriginAndDestination';
import { RouteIndex } from '@/app/enum';
import mixinMobile from '../../../misc/mixinMobile.js';

export default {
  mixins: [mixinMobile],
  data() {
    return {
      debounce: {
        timeout: 0,
        duration: 1500,
      },
      optionSelect: {
        loading: false,
        ifClickFrom: 'display: none;',
        ifClickTo: 'display: none;',
        triggerClickFrom: false,
        triggerClickTo: false,
      },
      classOptionsSelect: {
        onScroll: 'option-select__content',
        onHover: 'hover-option',
        dataChecker: 'data-checker',
      },
    };
  },
  computed: {
    decideClass() {
      return this.optionSelect.triggerClickFrom ? 'from' : 'to';
    },
    getScrollDivOption() {
      return `${this.classOptionsSelect.onScroll}-${this.decideClass}`;
    },
    getClassHover() {
      return `${this.classOptionsSelect.onHover}-${this.decideClass}`;
    },
  },
  methods: {
    /** debounce on change input search */
    async handleOnChangeFrom(text) {
      this.optionSelect.loading = true;
      if (this.debounce.timeout) {
        clearTimeout(this.debounce.timeout);
      }

      this.debounce.timeout = setTimeout(async () => {
        const listOrigin = await service.getAllBySearch(RouteIndex.ORIGIN, text);
        this.$store.dispatch('setListOrigin', listOrigin);
        this.optionSelect.loading = false;
      }, this.debounce.duration);
    },
    /** debounce on change input search */
    async handleOnChangeDestination(text) {
      this.optionSelect.loading = true;
      if (this.debounce.timeout) {
        clearTimeout(this.debounce.timeout);
      }

      this.debounce.timeout = setTimeout(async () => {
        const listDestination = await service.getAllBySearch(RouteIndex.DESTINATION, text);
        this.$store.dispatch('setListDestination', listDestination);
        this.optionSelect.loading = false;
      }, this.debounce.duration);
    },
    makeOffBorderRadius(triggerInputOpen) {
      const getCardBox = document.querySelector('#shipping-rate-border');
      if (!this.isMobileSize && triggerInputOpen && getCardBox) {
        getCardBox.style.borderBottomRightRadius = '0';
        getCardBox.style.borderBottomLeftRadius = '0';
      }

      if (!this.isMobileSize && !triggerInputOpen && getCardBox) {
        getCardBox.style.borderBottomRightRadius = '10px';
        getCardBox.style.borderBottomLeftRadius = '10px';
      }
    },
    toggleSwitch(keyTrigger, keyDisplay) {
      this.optionSelect[keyTrigger] = !this.optionSelect[keyTrigger];
      this.makeOffBorderRadius(this.optionSelect[keyTrigger]);
      this.optionSelect[keyTrigger === 'triggerClickFrom' ? 'triggerClickTo' : 'triggerClickFrom'] = false;
      this.optionSelect[keyTrigger === 'triggerClickFrom' ? 'ifClickTo' : 'ifClickFrom'] = 'display: none;';
      if (this.optionSelect[keyTrigger]) {
        if (this.isMobileSize) {
          this.optionSelect[keyDisplay] = 'display: block; top: -.5rem; width: 98.888%; z-index: 2;';
        } else {
          this.optionSelect[keyDisplay] = 'display: block; z-index: 2;';
        }
      } else {
        this.optionSelect[keyDisplay] = 'display: none;';
      }
    },
    deleteClassHoverDuringTriggerClick() {
      const active = document.querySelector(`.${this.classOptionsSelect.onHover}-to`);
      const activeFrom = document.querySelector(`.${this.classOptionsSelect.onHover}-from`);
      this.deleteClassHover(active);
      this.deleteClassHover(activeFrom);
    },
    handleScroll(activeElement) {
      activeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
    },
    deleteClassHover(activeElement) {
      if (activeElement) {
        activeElement.classList.remove(`${this.classOptionsSelect.onHover}-to`);
        activeElement.classList.remove(`${this.classOptionsSelect.onHover}-from`);
      }
    },
    handleDropdown(e) {
      const liElement = document.querySelector(`.${this.getScrollDivOption} li`);
      let active = document.querySelector(`.${this.getClassHover}`) || liElement;
      if (e.keyCode === 40) {
        this.deleteClassHover(active);
        active = active.nextElementSibling || active;
        this.handleScroll(active);
      } else if (e.keyCode === 38) {
        this.deleteClassHover(active);
        active = active.previousElementSibling || active;
        this.handleScroll(active);
      } else if (e.target.hasAttribute(this.classOptionsSelect.dataChecker)) {
        this.deleteClassHover(active);
        active = e.target;
      }
      if (active) {
        active.classList.add(`${this.getClassHover}`);
      }
      // active.scrollIntoView({ behavior: "smooth", block: "end", inline: "end" })
    },
    async getListOrigin() {
      const listOrigin = await service.getAllDefault(RouteIndex.ORIGIN);
      this.$store.dispatch('setListOrigin', listOrigin);
    },
    async getListDestination() {
      const listDestination = await service.getAllDefault(RouteIndex.DESTINATION);
      this.$store.dispatch('setListDestination', listDestination);
    },
  },
  mounted() {
    this.$el.addEventListener('keydown', this.handleDropdown);
    this.$el.addEventListener('mouseover', this.handleDropdown);
  },
  async created() {
    this.optionSelect.loading = true;
    this.optionSelect.loading = false;
  },
  beforeDestroy() {
    this.$el.removeEventListener('keydown', this.handleDropdown);
    this.$el.removeEventListener('mouseover', this.handleDropdown);
    clearTimeout(this.debounce.timeout);
  },
};
