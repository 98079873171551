import PromoRepository from '../api/promo.js';
import { TypeStatus } from '../enum/promo.js';
import { TimeMapper } from '@/misc/timeMapper';
import '../type/promo.js'

class PromoUseCase {
  constructor() {
    /**
      @type {PromoRepository}
     * */
    this.api = new PromoRepository();
  }

  /**
   * sort by active and active date
   * @return {Promise<{data: Promo[], err: Error}>}
   */
  async getAll(page = 1, perPage = 10) {
    const t = new Date();
    const qDate = TimeMapper.SetFormatDate(t.toDateString(), false, ['-', '-', '-'], false, false).split('-').reverse().join('-');

    let query = `page=${page}&s=active&order_by=active_date&date=${qDate}`;
    if (perPage > 20) {
      query += '&per_page=20';
    } else {
      query += `&per_page=${perPage}`;
    }

    const resp = await this.api.getAll(query)
    if (resp.data.length > 0) {
      const todayDate = new Date(new Date().toDateString());
      resp.data = resp.data.filter((v) => {
        const activeDate = new Date(new Date(v.active_date).toDateString());
        const expiryDate = new Date(new Date(v.expiry_date).toDateString());
        return v.status === TypeStatus.ACTIVE
          && activeDate <= todayDate
          && expiryDate >= todayDate;
      });
      resp.data.sort((a, b) => new Date(b.active_date) - new Date(a.active_date));
    }
    return resp;
  }
}

export default new PromoUseCase();
