export class TimeMapper {
  /**
     *
     * @param obj {Object}
     * @param targetKey {string}
     * @param separators {Array<string>}
     * @return {Object}
     * @private
     */
  static _setValueTimeInObj(obj, targetKey, separators = ['/', '/', '-', ':']) {
    if (obj[targetKey] && obj[targetKey] !== '') {
      obj[targetKey] = this.SetFormatDate(obj[targetKey], false, separators, true);
    } else {
      obj[targetKey] = '-';
    }
    return obj;
  }

  /**
     *
     * @param dateString {string}
     * @param withMonthName {boolean} // if true gonna be with Name, otherwise digit such as 11, 09
     * @param separators {Array<String>} // to combination date if u need with "-" or "/" or just space " "
     * @param showClock {boolean} // if true gonna show the clock of time such as 16:30, 04:09
     * @param isLangId {boolean} // if true gonna show the month name in bahasa
     * @return {string}
     * @constructor
     */
  static SetFormatDate(dateString, withMonthName, separators = ['/', '/', '-', ':'], showClock = false, formatDatePublished = false, isLangId = false) {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December',
    ];
    const monthNamesId = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni',
      'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember',
    ];

    /* command this if you change the format of month names
         const  monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
         */

    const t = new Date(dateString);

    // set the month of date either with digit or custom name :)
    let getMonth = (`0${t.getMonth() + 1}`).slice(-2);
    if (withMonthName) {
      if (isLangId) {
        getMonth = monthNamesId[t.getMonth()];
      } else {
        getMonth = monthNames[t.getMonth()];
      }
    }


    // set the final of time
    const finalTime = (`0${t.getDate()}`).slice(-2) + separators[0] + getMonth + separators[1] + t.getFullYear();
    const withClock = `${finalTime} ${separators[2]} ${(`0${t.getHours()}`).slice(-2)
    }${separators[3]}${(`0${t.getMinutes()}`).slice(-2)}`;

    // set format published time
    if (formatDatePublished) {
      const date = t.getFullYear() + separators[0] + getMonth + separators[1] + (`0${t.getDate()}`).slice(-2);
      const dateTime = `${date} ${(`0${t.getHours()}`).slice(-2)
      }${separators[2]}${(`0${t.getMinutes()}`).slice(-2)}${separators[2]}${(`0${t.getSeconds()}`).slice(-2)}`;

      return dateTime;
    }

    return showClock ? withClock : finalTime;
  }

  /**
     *
     * @param obj {Object}
     * @param listKey {Array<string>}
     * @return {Object}
     * @constructor
     */
  static SetKeyOfTheValueToTime(obj, listKey) {
    for (const key of listKey) {
      this._setValueTimeInObj(obj, key.trim());
    }
    return obj;
  }
}
