import InternalAPI from '../baseAxios.js';
import '../type/promo.js'

/**
 * @borrows InternalAPI
 */
export default class PromoRepository extends InternalAPI {
  constructor() {
    super();
    /**
     *
     * @type {string}
     */
    this.endpoint = '/v1/program/data';
  }

  /**
   * @param {string} query
   * @return {Promise<{data: Promo[], err: Error}>}
   */
  async getAll(query) {
    try {
      /**
       *
       * @type {AxiosResponse<Object>}
       */
      const res = await this.$axios.get(`${this.endpoint}?${query}`);
      if (res.status === 200) {
        if (res.data) {
          return { data: res.data.data, err: null };
        }
      }
      return { data: [], err: null };
    } catch (err) {
      return { data: [], err: this.setErrorMessage(err.response.data) };
    }
  }
}
