export default {
  data() {
    return {
      form: {
        from: {
          route_code: '',
          route_id: 0,
          district_code: '',
        },
        to: {
          route_code: '',
          route_id: 0,
          district_code: '',
        },
        weight: '',
      },
    };
  },
};
