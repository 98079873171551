import InternalAPI from '../baseAxios.js';
import '../type/banner';

/** @borrows InternalAPI */
export default class BannerRepository extends InternalAPI {
  constructor() {
    super();
    /** @type {string} */
    this.endpoint = '/v2/banner';
  }

  /**
     *
     * @param queries
     * @return {Promise<{data: Banner, err: null}|{data: null, err: ErrorState}>}
     */
  async getAll(queries) {
    try {
      /** @type {AxiosResponse<Object>} */
      const res = await this.$axios.get(this.endpoint + '/data?' + queries);
      if (res.status === 200) {
        if (res.data) {
          return { data: res.data, err: null };
        }
      }
      return { data: null, err: null };
    } catch (err) {
      return { data: null, err: this.setErrorMessage(err.response) };
    }
  }
}
