import InternalAPI from '../../../../app/baseAxios';
import '../types';

/** @borrows InternalAPI */
export default class AgentDistrictRepository extends InternalAPI {
  constructor() {
    super();
    /** @type {string} */
    this.endpoint = '/v1/network';
  }

  /**
     *
     * @param input {String}
     * @return {Promise<{data: ?AgentLocation, err: Error}>}
     */
  async findDistrict(input) {
    try {
      const res = await this.$axios.get(`${this.endpoint}/subdistrict/data?page=1&per_page=100&q=${input}`);
      if (res.status === 200) {
        if (res.data) {
          return { data: res.data, err: null };
        }
      }
      return { data: res.data, err: null };
    } catch (err) {
      return { data: null, err: this.setErrorMessage(err.response.data.message) };
    }
  }

  /**
     *
     * @param subID {number}
     * @return {Promise<{data: ?POSLocation, err: Error}>}
     */
  async findAgentInDistrict(subID, page = 1, perPage = 10) {
    try {
      const res = await this.$axios.get(`${this.endpoint}/pos/data?page=${page}&per_page=${perPage}&sub_district_id=${subID}`);
      if (res.status === 200) {
        if (res.data) {
          return { data: res.data, err: null };
        }
      }
    } catch (err) {
      return { data: null, err: this.setErrorMessage(err.response.data.message) };
    }
  }
}
