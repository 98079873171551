<template>
  <div>
    <div
      id="shipping-rate-border"
      class="keep-height"
      :class="{
        'need-dropdown': needDropDown,
        'ml-3 mr-3': !isMobileSize,
        'homepage-feature-card': useDropdown,
      }"
    >
      <!-- dropdown -->
      <input
        v-if="needDropDown"
        id="'dropdown-rates"
        type="checkbox"
        value="claim"
        class="input-checkbox-toggle"
      >
      <label
        v-if="needDropDown"
        for="'dropdown-rates"
        aria-label="Toggle Dropdown rates"
      >
        <div class="dropdown-rates" />
      </label>
      <div
        class="bold-text"
        :class="{ 'text-rates': isMobileSize }"
      >
        {{ feature.title }}
      </div>
      <div
        class="card-feature"
        :class="{
          'dropdown-item-new': needDropDown,
          'text-rates': isMobileSize,
          'feature-label': !needDropDown,
        }"
      >
        <div class="input-box feature-label__item">
          <div>
            <BaseInput
              v-model="form.from.route_code"
              :name="`${feature.name}-${feature.info.from}`"
              :placeholder="feature.info.from"
              :class-name="classInputText"
              :label="useDropdown || isMobileSize ? '' : 'Kota Asal'"
              @click="clickFrom()"
              @input="handleOnChangeFromChild"
            />
            <OptionSelect
              v-if="isMobileSize || !useDropdown"
              :data-list="GET_LIST_ORIGIN"
              :show-loading-search="optionSelect.loading"
              :style-display="optionSelect.ifClickFrom"
              :decide-class-hover="getClassHover"
              :trigger-class-listener-on-scroll="
                `${classOptionsSelect.onScroll}-from`
              "
              key-value="route_code"
              message="<p style='font-size: 12px'>Untuk Sementara Area Anda Belum Terjangkau Sehingga Tarif Tidak Tersedia</p>"
              @click="chooseFrom"
            />
          </div>
          <div
            v-if="!isMobileSize"
            class="ml-2 mr-2"
          />
          <div>
            <BaseInput
              v-model="form.to.route_code"
              :name="`${feature.name}-${feature.info.to}`"
              :placeholder="feature.info.to"
              :class-name="classInputText"
              :label="useDropdown || isMobileSize ? '' : 'Kota Tujuan'"
              @click="clickTo()"
              @input="handleOnChangeDestinationChild"
            />

            <OptionSelect
              v-if="isMobileSize || !useDropdown"
              :data-list="GET_LIST_DESTINATION"
              :show-loading-search="optionSelect.loading"
              :style-display="optionSelect.ifClickTo"
              :decide-class-hover="getClassHover"
              :trigger-class-listener-on-scroll="
                `${classOptionsSelect.onScroll}-to`
              "
              key-value="route_code"
              message="<p style='font-size: 12px'>Untuk Sementara Area Anda Belum Terjangkau Sehingga Tarif Tidak Tersedia</p>"
              @click="chooseTo"
            />
          </div>
          <div
            v-if="!isMobileSize"
            class="ml-2 mr-2"
          />
          <BaseInput
            v-model="form.weight"
            :custom-class="customClassInputNumber"
            :minimal-input-number="1"
            :name="`${feature.name}`"
            :placeholder="feature.info.weight"
            :class-name="classInputNumber"
            :maximal-input-number="100"
            :label="useDropdown || isMobileSize ? '' : 'Berat'"
            style="width: max-content;"
            @click="triggerClickInputNumber"
            @input="handleOnChangeWeight"
            @paste="$event.preventDefault()"
          />
        </div>
        <div
          v-if="!isMobileSize"
          class="feature-label__button"
        >
          <Button
            text="Cek Tarif"
            type-class="red"
            @click="checkRates"
          />
        </div>
      </div>
      <div
        v-if="useDropdown || isMobileSize"
        class="card-feature mb-1"
        :class="{
          'dropdown-item-new': needDropDown,
          'mt-1': !isMobileSize && useDropdown,
          'mt-3': isMobileSize && !useDropdown,
        }"
      >
        <div class="small-info mb-1">
          {{ feature.input.placeHolder }}
        </div>
      </div>
      <div
        v-if="isMobileSize"
        :id="
          useDropdown ? 'card-feature__button-homepage' : 'card-feature__button'
        "
        :class="{ 'dropdown-item-new': useDropdown }"
      >
        <Button
          text="Cek Sekarang"
          type-class="red"
          @click="checkRates"
        />
      </div>
    </div>

    <!-- dropdown option for desktop view -->
    <OptionSelectDesktop
      v-if="!isMobileSize && useDropdown"
      ref="optionNotMobile"
      :show-both="true"
      :show-origin="false"
      :show-destination="false"
      @choosefrom="chooseFrom"
      @chooseto="chooseTo"
    />
    <!-- end dropdown option for desktop view -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from '@/components/new-button/Button.vue';
import BaseInput from '@/components/new-input/Input.vue';
import OptionSelect from '@/components/new-option-select/OptionSelect.vue';
import OptionSelectDesktop from './OptionSelectDesktop.vue';
import mixin from '../mixins';
import mixinShippingPrice from '../../../misc/mixinCheckPrice.js';
import '../app/types';

export default {
  name: 'ShippingPrice',
  components: {
    Button,
    BaseInput,
    OptionSelect,
    OptionSelectDesktop,
  },
  mixins: [mixin, mixinShippingPrice],
  props: {
    useDropdown: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dropdownClass: {
        wrapper: 'need-dropdown',
        label: 'dropdown-rates',
        card: 'dropdown-item-new',
      },
      form: {
        from: {
          route_code: '',
          route_id: 0,
          district_code: '',
        },
        to: {
          route_code: '',
          route_id: 0,
          district_code: '',
        },
        weight: '',
      },
      /** @type {string} */
      customClassInputNumber: '',
      feature: {
        title: 'Cek Tarif',
        name: 'Check Tarif',
        info: {
          from: 'Asal',
          to: 'Tujuan',
          weight: 'Berat(kg)',
        },
        input: {
          name: 'titik',
          placeHolder: 'Masukkan asal, tujuan & berat barang Anda',
        },
      },
      variableEventOption: {
        from: {
          click: 'triggerClickFrom',
          style: 'ifClickFrom',
        },
        to: {
          click: 'triggerClickTo',
          style: 'ifClickTo',
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      'CHECK_PRICE',
      'GET_FORM_CHECK_SHIPPING_PRICE',
      'GET_LIST_ORIGIN',
      'GET_LIST_DESTINATION',
    ]),
    IS_GENESIS() {
      return process.env.VUE_APP_IS_GENESIS === 'true';
    },
    needDropDown() {
      return this.isMobileSize && this.useDropdown;
    },
    widthClassInput() {
      if (this.listenWidth > 1774) {
        return { text: 'input-4xl', number: 'input-sm' };
      }
      if (this.listenWidth > 1300) {
        return { text: 'input-xl', number: 'input-sm' };
      }
      if (this.listenWidth > 991) {
        return { text: 'input-md', number: 'input-xxs' };
      }
      if (this.listenWidth < 991 && this.listenWidth > 600) {
        return { text: 'input-xxl', number: 'input-sm' };
      }
      return { text: 'input-xl', number: 'input-xss' };
    },
    classInputText() {
      let className = this.widthClassInput.text;
      if (this.isMobileSize && this.useDropdown) {
        className = 'input-xl mb-2';
      }
      if (!this.isMobileSize && this.useDropdown) {
        className = 'input-xxs';
      }

      if (this.isMobileSize && !this.useDropdown) {
        className += ' mb-2';
      }
      return className;
    },
    classInputNumber() {
      let className = this.widthClassInput.number;
      if (this.needDropDown || (this.isMobileSize && !this.useDropdown)) {
        className = 'input-xs mb-4';
      }
      if (!this.isMobileSize && this.useDropdown) {
        className = 'input-xxs';
      }
      return className;
    },
  },
  created() {
    this.getListOrigin();
    this.getListDestination();
  },
  mounted() {
    if (this.GET_FORM_CHECK_SHIPPING_PRICE !== {} && this.$route.path !== '/') {
      this.form = this.GET_FORM_CHECK_SHIPPING_PRICE;
    }
  },
  methods: {
    reactiveChangeInputNumberClass() {
      if (this.form.weight.length === 0) {
        this.customClassInputNumber = '';
      } else {
        this.customClassInputNumber = 'input-number';
      }
    },
    handleOnChangeWeight(text) {
      const checkDotComma = (this.form.weight.match(/\./g) || []).length + (this.form.weight.match(/,/g) || []).length > 1
      const allowNumberDotComma = (/^[0-9.,]+$/.test(text))
      const alphabet = /([A-z])/g
      if (isNaN(text.charAt(0)) || (!allowNumberDotComma && text) || checkDotComma) {
        this.form.weight = this.form.weight.slice(0, -1)
        document.querySelector("[name='Check Tarif']").value = this.form.weight
      }
      
      //handle swift keyboard
      if(alphabet.test(text)) {
        this.form.weight = this.form.weight.substring(0, this.form.weight.length - (this.form.weight.match(alphabet) || []).length)
        document.querySelector("[name='Check Tarif']").value = this.form.weight
      }
      if (text.length > 4 && +text.replace(',', '.') > 1000) {
        this.form.weight = this.form.weight.substring(0, 3)
      } else if (+text.replace(',', '.') > 1000) {
        this.form.weight = "1000"
      }
      this.reactiveChangeInputNumberClass();
    },
    triggerClickInputNumber() {
      this.reactiveChangeInputNumberClass();
    },
    checkRates() {
      if (
        this.form.from.route_id <= 0
        || this.form.from.route_code.length === 0
      ) {
        this.clickFrom();
      } else if (
        this.form.to.route_id <= 0
        || this.form.to.route_code.length === 0
      ) {
        this.clickTo();
      } else if (this.form.weight.length === 0) {
        this.form.weight = '1';
        this.reactiveChangeInputNumberClass();
      } else {
        const payload = {
          origin: this.IS_GENESIS
            ? this.form.from.district_code.toUpperCase()
            : this.form.from.route_code.toUpperCase(),
          destination: this.IS_GENESIS
            ? this.form.to.district_code.toUpperCase()
            : this.form.to.route_code.toUpperCase(),
          weight: +this.form.weight.replace(',', '.'),
        };
        if (this.IS_GENESIS) {
          payload.route_code_origin = this.form.from.route_code.toUpperCase();
          payload.route_code_destination = this.form.to.route_code.toUpperCase();
        }
        this.$store.dispatch('initFillFormShippingPrice', this.form);
        this.$store.dispatch('initCheckPrice', payload);
        this.$emit('click');
      }
    },
    /** debounce on change input search */
    async handleOnChangeFromChild(text) {
      await this.handleOnChangeFrom(text);
      if (!this.isMobileSize && this.useDropdown) {
        await this.$refs.optionNotMobile.handleOnChangeFrom(text);
      } else {
        await this.handleOnChangeFrom(text);
      }
    },
    /** debounce on change input search */
    async handleOnChangeDestinationChild(text) {
      if (!this.isMobileSize && this.useDropdown) {
        await this.$refs.optionNotMobile.handleOnChangeDestination(text);
      } else {
        await this.handleOnChangeDestination(text);
      }
    },
    clickFrom() {
      if (!this.isMobileSize && this.useDropdown) {
        this.$refs.optionNotMobile.toggleSwitch(
          this.variableEventOption.from.click,
          this.variableEventOption.from.style,
        );
      }

      if (this.isMobileSize || !this.useDropdown) {
        this.toggleSwitch(
          this.variableEventOption.from.click,
          this.variableEventOption.from.style,
        );
      }

      this.deleteClassHoverDuringTriggerClick();
      if (this.form.to.route_id <= 0) {
        this.form.to.route_code = '';
      }
    },
    clickTo() {
      if (!this.isMobileSize && this.useDropdown) {
        this.$refs.optionNotMobile.toggleSwitch(
          this.variableEventOption.to.click,
          this.variableEventOption.to.style,
        );
      }
      if (this.isMobileSize || !this.useDropdown) {
        this.toggleSwitch(
          this.variableEventOption.to.click,
          this.variableEventOption.to.style,
        );
      }

      this.deleteClassHoverDuringTriggerClick();
      if (this.form.from.route_id <= 0) {
        this.form.from.route_code = '';
      }
    },
    /**
     *
     * @param data {RoutePlace}
     */
    chooseFrom(data) {
      if (this.IS_GENESIS) {
        this.form.from.district_code = data.district_code;
      }
      this.form.from.route_code = data.route_code;
      this.form.from.route_id = data.route_id;
      this.clickFrom();
    },
    /**
     *
     * @param data {RoutePlace}
     */
    chooseTo(data) {
      if (this.IS_GENESIS) {
        this.form.to.district_code = data.district_code;
      }
      this.form.to.route_code = data.route_code;
      this.form.to.route_id = data.route_id;
      this.clickTo();
    },
  },
};
</script>
<style lang="scss" scope>
@import './../style.scss';
@import './../../../assets/css/spacing';
@import './../dropdown';
@import '../../../assets/css/feature/style';

.homepage-feature-card {
  position: relative;
  width: 395px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
  padding: 1rem 1.25rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  @include for-size(tablet-portrait) {
    margin: 0 auto;
  }
  @include for-size(mobile) {
    margin: 0 auto;
    max-width: 86%;
  }

  @media (min-width: 480px) and (max-width: 600px) {
    max-width: 100%;
  }

  @media (min-width: 480px) and (max-width: 768px) {
    margin-bottom: 4%;
  }

  #card-feature__add__track,
  #card-feature__add__claim {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    > div {
      margin-right: 10px;
    }
  }

  .card-feature {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    max-width: 100%;
    align-items: baseline;

    > div {
      img {
        cursor: pointer;
      }
    }
  }

  .card-feature-between {
    @include do-flex(row, space-between, 0 0 0 0);
    padding-right: 0.5rem;

    div {
      width: 76px;
      max-width: 100%;
    }
  }
}

.card-feature {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  max-width: 100%;
  align-items: baseline;

  > div {
    img {
      cursor: pointer;
    }
  }
}

#card-feature__button-homepage {
  text-align: center;
  margin: 1rem auto auto auto;
}

#card-feature__button {
  text-align: center;
  margin: 1rem 0.85rem auto 0;
  padding-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
  @include for-size(tablet-portrait) {
    display: block;
  }
}

.feature-label {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  @include for-size(mobile) {
    display: block;
  }

  @include for-size(tablet-portrait) {
    justify-content: flex-start;
  }

  &__item {
    flex: 1;
    flex-wrap: wrap;
    @include for-size(mobile) {
      flex-wrap: nowrap;
    }
  }

  &__button {
    margin-bottom: 0.3rem;
  }
}

input[name="Check Tarif"].style-input-sm {
  padding-left: 10px !important;
  padding-right: 24px !important;
}
input[name="Check Tarif"].style-input-xxs {
  width: 71px !important;
  padding-right: 24px !important;
}
input[name="Check Tarif"].style-input-xs {
  width: 80px !important;
  padding-right: 24px !important;
}
input[name="Check Tarif"]:placeholder-shown {
  padding-right: unset !important;
}
</style>
