<template>
  <div>
    <div :class="classMarginNotForMobile()">
      <BaseOptionSelect
        :data-list="GET_LIST_ORIGIN"
        :show-loading-search="optionSelect.loading"
        :style-display="optionSelect.ifClickFrom"
        :decide-class-hover="getClassHover"
        :trigger-class-listener-on-scroll="`${classOptionsSelect.onScroll}-from`"
        key-value="route_code"
        message="<p style='font-size: 12px'>Untuk Sementara Area Anda Belum Terjangkau Sehingga Tarif Tidak Tersedia</p>"
        @click="chooseFrom"
      />
    </div>
    <div :class="classMarginNotForMobile()">
      <BaseOptionSelect
        :data-list="GET_LIST_DESTINATION"
        :show-loading-search="optionSelect.loading"
        :style-display="optionSelect.ifClickTo"
        :decide-class-hover="getClassHover"
        :trigger-class-listener-on-scroll="`${classOptionsSelect.onScroll}-to`"
        key-value="route_code"
        message="<p style='font-size: 12px'>Untuk Sementara Area Anda Belum Terjangkau Sehingga Tarif Tidak Tersedia</p>"
        @click="chooseTo"
      />
    </div>
  </div>
</template>

<script>
import BaseOptionSelect from "@/components/new-option-select/OptionSelect.vue"
import mixin from "../mixins"
import {mapGetters} from "vuex";

export default {
  name: "OptionSelectDesktop",
  components: {BaseOptionSelect},
  mixins: [mixin],
  props: {
    showBoth: {
      type: Boolean,
    },
    showOrigin: {
      type: Boolean,
    },
    showDestination: {
      type: Boolean,
    }
  },
  computed: {
    ...mapGetters(["GET_LIST_ORIGIN", "GET_LIST_DESTINATION"])
  },
  methods: {
    classMarginNotForMobile() {
      if (!this.isMobileSize) {
        return "ml-3 mr-3"
      }
      return ""
    },
    chooseFrom(data) {
      this.$emit("choosefrom", data)
    },
    chooseTo(data) {
      this.$emit('chooseto', data)
    }
  }
}
</script>
