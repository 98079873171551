import BannerRepository from '../api/banner.js';
import '../type/banner';

class BannerUseCase {
  constructor() {
    /** @type {BannerRepository} */
    this.api = new BannerRepository();
  }

  /**
     *
     * @param typeBanner {string}
     * @return {Promise<{data: Banner, err: null}|{data: null, err: ErrorState}>}
     */
  async getAll(banner_page) {
    const queries = 'status=ACTIVE&banner_page=' + banner_page
    const {data, err} = await this.api.getAll(queries)
    if (err && err === 'record not found') {
      return { data: null, err: null };
    }
    return { data, err };
  }
}

export default new BannerUseCase();
